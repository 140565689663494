<template>
  <div>
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import { BCol, BRow } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    UserViewUserInfoCard,
  },
  setup() {
    const userData = getUserData()

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    return {
      userData,
    }
  },
}
</script>

<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>

          <!-- User Info: Left col -->
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >

            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
              <div v-if="selectedFile">
                <b-img
                  thumbnail
                  :src="previewUrl"
                  alt="Nova foto de perfil"
                  :style="{ width: '100px', height: '100px' }"
                />

              </div>
              <b-avatar
                v-else
                :src="userData.imagem"
                :text="avatarText(userData.name)"
                :variant="`light-${resolveUserRoleVariant(userData.roles[0])}`"
                size="104px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h4 class="mb-0">
                    {{ userData.name }}
                  </h4>
                  <span class="card-text">{{ userData.email }}</span>
                </div>
                <div class="d-flex flex-wrap">
                  <b-form-file
                    ref="fileInput"
                    label="Imagem"
                    accept="image/jpeg, image/png"
                    label-for="imagem"
                    browse-text="Procurar"
                    placeholder="Escolha uma imagem para o perfil"
                    drop-placeholder="Escolha uma imagem para o perfil"
                    @change="handleFileChange"
                  />
                </div>
              </div>

            </div>

          </b-col>

          <b-col
            cols="12"
            xl="6"
          >
            <h5 class="mb-1">
              Perfil: {{ userData.roles[0] }}
            </h5>
            <validation-provider
              #default="validationContext"
              name="Telefone"
            >
              <b-form-group
                label="Telefone"
                label-for="telefone"
              >
                <b-form-input
                  id="contact"
                  ref="telefoneInput"
                  v-model="userData.telefone"
                  v-mask="'(##) # ####-####'"
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: (00) 0 0000-0000"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            xl="12"
            class="mt-2"
          >
            <h5>Alterar Senha</h5>
          </b-col>
          <b-col
            cols="6"
            xl="6"
          >

            <validation-provider
              #default="validationContext"
              name="senha"
              vid="Password"
              rules="password"
            >
              <b-form-group
                label="Nova Senha"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  ref="Password"
                  v-model="userData.password"
                  name="Password"
                  type="password"
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Informe uma nova senha"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="6"
            xl="6"
          >
            <validation-provider
              #default="validationContext"
              name="confirmar senha "
              rules="confirmed:Password"
            >
              <b-form-group
                type="password"
                label="Confirmar senha"
                label-for="confirmar"
              >
                <b-form-input
                  id="confirmar"
                  ref="confirmar"
                  v-model="userData.password_confirmation"
                  type="password"
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Confirme a nova senha"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            variant="success"
            type="submit"
            class="mb-1 mb-sm-0 mr-0 ml-sm-1"
          >
            Salvar
          </b-button>

        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { confirmed, password, required } from '@validations'
import store from '@/store'
import { ref } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BImg,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BFormFile,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    const userData = ref(props, 'userData')
    // Use toast
    const toast = useToast()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const onSubmit = async () => {
      const dadosUsuarios = { ...userData.value }

      await store.dispatch('app-user/updatePerfil', dadosUsuarios)
      const respo = await store.dispatch('app-user/fetchMyProfile')
      localStorage.removeItem('userData')
      localStorage.setItem('userData', JSON.stringify(respo.data))
      location.reload()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Dados atualizados com sucesso, recarregando Perfil',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    }

    return {
      avatarText,
      resolveUserRoleVariant,
      onSubmit,
      refFormObserver,
      getValidationState,

    }
  },
  data() {
    return {
      selectedFile: null,
      previewUrl: null,
      confirmed,
      required,
      password,
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.userData.imagem = file

      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.previewUrl = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewUrl = null
      }
    },
  },

}
</script>
